import React from 'react';
import { Box, Flex, Styled, Image } from 'theme-ui';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import Container from '../../components/Container';
import Button from '../../components/Button';
import { DASHBOARD_ROUTE, SIGN_UP_ROUTE } from '../../constants/routes';
import { useAuth } from '../../hooks/auth';
import toggles from '../../utils/toggles';

function Hero({
	title,
	subTitle,
	ctaText,
	ctaHref,
	anonymousTrialPrefixText,
	anonymousTrialText,
	anonymousTrialPostfixText,
	featureImageSrc,
}) {
	const auth = useAuth();
	const router = useRouter();
	const { isSignupEnabled } = toggles;
	const handleAnonymousLogin = () => {
		auth.logInAnonymously()
			.then((user) => {
				router.push(DASHBOARD_ROUTE);
			})
			.catch((error) => error);
	};
	return (
		<Box
			as="section"
			sx={{
				position: 'relative',
				paddingBottom: ['10vh', '15vh', null, 0],
				'&:before': {
					borderColor: (theme) => `transparent transparent ${theme.colors.gray50}`,
					borderStyle: 'solid',
					borderWidth: 0,
					borderLeftWidth: '100vw',
					borderBottomWidth: ['15vh', null, '25vh', '35vh'],
					content: '""',
					display: 'block',
					height: 0,
					position: 'absolute',
					right: 0,
					width: 0,
					zIndex: -1,
					bottom: 0,
				},
			}}>
			<Container>
				<Flex
					flexWrap="wrap"
					mx={-5}
					sx={{
						flexDirection: [
							'column-reverse',
							'column-reverse',
							'column-reverse',
							'row',
						],
						height: ['auto', 'auto', 'calc(100vh - 73px)'],
						alignItems: 'center',
						justifyContent: 'center',
					}}>
					<Box
						sx={{
							px: 5,
							width: ['100%', '100%', '100%', '50%'],
							marginTop: [8, null, null, 0],
							textAlign: ['center', 'center', 'center', 'left'],
						}}>
						<Styled.h1 as="h1">{title}</Styled.h1>
						<Box
							sx={{
								color: 'text',
								fontSize: [4, 5, 5],
								marginTop: [6, 6, 7],
							}}>
							{subTitle}
						</Box>
						<Box mt={[6, 6, 7]}>
							<NextLink href={ctaHref}>
								<Button role="link" size="large" outlined variant="emphasis">
									{ctaText}
								</Button>
							</NextLink>
						</Box>
						{isSignupEnabled && (
							<Box marginTop={[6, 6, 7]}>
								<Styled.p>
									{anonymousTrialPrefixText}
									<Button
										variant="link"
										size="link"
										onClick={handleAnonymousLogin}>
										{anonymousTrialText}
									</Button>
								</Styled.p>
								<Styled.p color="gray400">{anonymousTrialPostfixText}</Styled.p>
							</Box>
						)}
					</Box>
					<Box
						sx={{
							px: 5,
							marginTop: [8, null, null, 0],
							width: ['100%', '100%', '100%', '50%'],
							textAlign: ['center', 'center', 'center', 'left'],
						}}>
						<Image src={featureImageSrc} alt={`Togglez - ${title}`} />
					</Box>
				</Flex>
			</Container>
		</Box>
	);
}

Hero.defaultProps = {
	title: 'Release Software Features Confidently',
	subTitle:
		'Create, organize and maintain feature toggles (aka. feature flags) and remote configurations across web, mobile and server-side applications.',
	featureImageSrc: '/images/svg/Togglez-hero_release_software_features_with_confidence.svg',
	anonymousTrialPrefixText: 'or ',
	anonymousTrialText: 'Try now without an account.',
	anonymousTrialPostfixText: "No account, No credit card. It's FREE!",
	ctaText: 'Get Started',
	ctaHref: SIGN_UP_ROUTE,
};
export default Hero;
