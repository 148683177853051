/** @jsx jsx */
import { jsx } from 'theme-ui';
import BaseLayout from '../layouts/BaseLayout';
import Header from '../blocks/Header';
import Hero from '../blocks/Hero';
const HomePage = () => {
	return (
		<BaseLayout header={<Header />}>
			<Hero />
		</BaseLayout>
	);
};

export default HomePage;
